import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify";


import VueHtmlToPaper from "vue-html-to-paper";

Vue.use(Vuetify);
Vue.use(VueAxios, axios);
//axios.defaults.baseURL = "https://app.tlbak.com/api/v1/";
axios.defaults.baseURL = "https://testapp.tlbak.com/api/v1/";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1/";


const options = {
  name: "_blank",
  specs: [
    "fullscreen=1200",
    "titlebar=yes",
    "scrollbars=no",
    "width=800",
    "height=800",
  ],
  styles: ["https://app.tlbak.com/print_label1.css"],
};

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

// axios.defaults.baseURL = "";
// axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

import "@/styles/main.scss";
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
