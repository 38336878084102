import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const contents = () => import("@/views/contents.vue");
const attributes = () => import("@/views/attributes.vue");
const sections = () => import("@/views/sections.vue");
const groups = () => import("@/views/groups.vue");
const taxs = () => import("@/views/taxs.vue");
const brands = () => import("@/views/brands.vue");
const storages = () => import("@/views/storage/storages.vue");
const storage = () => import("@/views/storage/storage.vue");
const special = () => import("@/views/storage/special.vue");
const suppliers = () => import("@/views/suppliers/suppliers.vue");
const supplier = () => import("@/views/suppliers/supplier.vue");
const suppliers_orders = () => import("@/views/suppliers/suppliers_orders.vue");

const supplier_order = () => import("@/views/suppliers/supplier_order.vue");


const products = () => import("@/views/products/products.vue");
const product = () => import("@/views/products/product.vue");
const q_products = () => import("@/views/products/quick_products.vue");

// const pieces = () => import("@/views/pieces/pieces.vue");
const pro_pieces = () => import("@/views/pieces/pro_pieces.vue");
const setofpieces = () => import("@/views/pieces/setofpieces.vue");
const pieces_quan = () => import("@/views/pieces/pieces_quan.vue");
const expire = () => import("@/views/pieces/expire.vue");


// delivery
const deliveries = () => import("@/views/delivery/deliveries.vue");
const delivery = () => import("@/views/delivery/delivery.vue");

//orders
const orders = () => import("@/views/orders/orders.vue");
const newOrders = () => import("@/views/orders/new_orders.vue");
const locations = () => import("@/views/orders/locations.vue");
const rates = () => import("@/views/orders/rates.vue");

//customers
const customers = () => import("@/views/customers/customers.vue");
const customer = () => import("@/views/customers/customer.vue");
const transactions = () => import("@/views/customers/transactions.vue");
// campaigns
const campaigns = () => import("@/views/campaigns/campaigns.vue");
const campaign = () => import("@/views/campaigns/campaign.vue");

// Employees
const employees = () => import("@/views/employees/employees.vue");


// Recipes
const chefs = () => import("@/views/recipes/chefs.vue");
const recipes = () => import("@/views/recipes/recipes.vue");

const recipe = () => import("@/views/recipes/recipe.vue");

const definitions = () => import("@/views/definitions.vue");
const banners = () => import("@/views/banners.vue");
const coupons = () => import("@/views/coupons.vue");
const blocks = () => import("@/views/blocks.vue");
const expenses = () => import("@/views/expenses/expenses.vue");
const returner = () => import("@/views/returner.vue");
const payments = () => import("@/views/payments/payments.vue");
const reports = () => import("@/views/reports.vue");
const settings = () => import("@/views/settings.vue");
const dashboard = () => import("@/views/dashboard");
const translations = () => import("@/views/translations");
import notfound from "@/views/not_found.vue";

const logs = () => import("@/views/logs.vue");
const login = () => import("@/views/login.vue");
const routes = [
  {
    path: "/reports",
    name: "reports",
    component: reports,
    meta: {
      title: "reports",
      requiresAuth: true,
    },
  },

  {
    path: "/contents",
    name: "contents",
    component: contents,
    meta: {
      title: "contents",
      requiresAuth: true,
    },
  },
  {
    path: "/sections",
    name: "sections",
    component: sections,
    meta: {
      title: "sections",
      requiresAuth: true,
    },
  },
  {
    path: "/attributes",
    name: "attributes",
    component: attributes,
    meta: {
      title: "attributes",
      requiresAuth: true,
    },
  },
  {
    path: "/groups",
    name: "groups",
    component: groups,
    meta: {
      title: "groups",
      requiresAuth: true,
    },
  },
  {
    path: "/taxs",
    name: "taxs",
    component: taxs,
    meta: {
      title: "taxs",
      requiresAuth: true,
    },
  },
  {
    path: "/brands",
    name: "brands",
    component: brands,
    meta: {
      title: "brands",
      requiresAuth: true,
    },
  },

  {
    path: "/storages",
    name: "storages",
    component: storages,
    meta: {
      title: "storages",
      requiresAuth: true,
    },
  },
  
  {
    path: "/storage/:id?",
    name: "storage",
    component: storage,
    meta: {
      title: "storage",
      requiresAuth: true,
    },
  },
  {
    path: "/special",
    name: "special",
    component: special,
    meta: {
      title: "special",
      requiresAuth: true,
    },
  },

  {
    path: "/suppliers",
    name: "suppliers",
    component: suppliers,
    meta: {
      title: "suppliers",
      requiresAuth: true,
    },
  },
  {
    path: "/suppliers_orders",
    name: "suppliers_orders",
    component: suppliers_orders,
    meta: {
      title: "suppliers_orders",
      requiresAuth: true,
    },
  },
  
  {
    path: "/supplier_order/:id?",
    name: "supplier_order",
    component: supplier_order,
    meta: {
      title: "supplier_order",
      requiresAuth: true,
    },
  },
  
  {
    path: "/supplier/:id",
    name: "supplier",
    component: supplier,
    meta: {
      title: "supplier",
      requiresAuth: true,
    },
  },

  {
    path: "/products",
    name: "products",
    component: products,
    meta: {
      title: "products",
      requiresAuth: true,
    },
  },

  {
    path: "/product/:id?",
    name: "product",
    component: product,
    meta: {
      title: "product",
      requiresAuth: true,
    },
  },

  {
    path: "/q_products",
    name: "q_products",
    component: q_products,
    meta: {
      title: "q_products",
      requiresAuth: true,
    },
  },
  // {
  //   path: "/pieces",
  //   name: "pieces",
  //   component: pieces,
  //   meta: {
  //     title: "pieces",
  //     requiresAuth: true,
  //   },
  // },

  {
    path: "/pro_pieces/:id?",
    name: "pro_pieces",
    component: pro_pieces,
    meta: {
      title: "pro_pieces",
      requiresAuth: true,
    },
  },
  {
    path: "/pieces",
    name: "pieces",
    component: setofpieces,
    meta: {
      title: "pieces",
      requiresAuth: true,
    },
  },
  {
    path: "/pieces_quantity",
    name: "pieces_quan",
    component: pieces_quan,
    meta: {
      title: "pieces quantity",
      requiresAuth: true,
    },
  },
  {
    path: "/expire",
    name: "expire",
    component: expire,
    meta: {
      title: "expire",
      requiresAuth: true,
    },
  },


  
  {
    path: "/deliveries",
    name: "deliveries",
    component: deliveries,
    meta: {
      title: "deliveries",
      requiresAuth: true,
    },
  },
  {
    path: "/delivery/:id",
    name: "delivery",
    component: delivery,
    meta: {
      title: "delivery",
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: orders,
    meta: {
      title: "orders",
      requiresAuth: true,
    },
  },
  {
    path: "/newOrders",
    name: "newOrders",
    component: newOrders,
    meta: {
      title: "newOrders",
      requiresAuth: true,
    },
  },
  {
    path: "/rates",
    name: "rates",
    component: rates,
    meta: {
      title: "rates",
      requiresAuth: true,
    },
  },
  
  {
    path: "/locations",
    name: "locations",
    component: locations,
    meta: {
      title: "locations",
      requiresAuth: true,
    },
  },
  
  {
    path: "/customers",
    name: "customers",
    component: customers,
    meta: {
      title: "customers",
      requiresAuth: true,
    },
  },
  {
    path: "/customer/:id",
    name: "customer",
    component: customer,
    meta: {
      title: "customer",
      requiresAuth: true,
    },
  },
  {
    path: "/transactions",
    name: "transactions",
    component: transactions,
    meta: {
      title: "transactions",
      requiresAuth: true,
    },
  },

  {
    path: "/campaigns",
    name: "campaigns",
    component: campaigns,
    meta: {
      title: "campaigns",
      requiresAuth: true,
    },
  },
  {
    path: "/campaign/:id?",
    name: "campaign",
    component: campaign,
    meta: {
      title: "campaign",
      requiresAuth: true,
    },
  },

  {
    path: "/definitions",
    name: "definitions",
    component: definitions,
    meta: {
      title: "definitions",
      requiresAuth: true,
    },
  },
  {
    path: "/banners",
    name: "banners",
    component: banners,
    meta: {
      title: "banners",
      requiresAuth: true,
    },
  },
  {
    path: "/coupons",
    name: "coupons",
    component: coupons,
    meta: {
      title: "coupons",
      requiresAuth: true,
    },
  },

  {
    path: "/employees",
    name: "employees",
    component: employees,
    meta: {
      title: "employees",
      requiresAuth: true,
    },
  },
  {
    path: "/blocks",
    name: "blocks",
    component: blocks,
    meta: {
      title: "blocks",
      requiresAuth: true,
    },
  },
  {
    path: "/expenses",
    name: "expenses",
    component: expenses,
    meta: {
      title: "expenses",
      requiresAuth: true,
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: payments,
    meta: {
      title: "payments",
      requiresAuth: true,
    },
  },

  {
    path: "/returner",
    name: "returner",
    component: returner,
    meta: {
      title: "returner",
      requiresAuth: true,
    },
  },

  {
    path: "/chefs",
    name: "chefs",
    component: chefs,
    meta: {
      title: "chefs",
      requiresAuth: true,
    },
  },
  {
    path: "/recipes",
    name: "recipes",
    component: recipes,
    meta: {
      title: "recipes",
      requiresAuth: true,
    },
  },
  {
    path: "/recipe/:id?",
    name: "recipe",
    component: recipe,
    meta: {
      title: "recipe",
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: {
      title: "dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/translations",
    name: "translations",
    component: translations,
    meta: {
      title: "translations",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: {
      title: "settings",
      requiresAuth: true,
    },
  },
  {
    path: "/logs",
    name: "logs",
    component: logs,
    meta: {
      title: "logs",
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
    },
  },

  {
    path: "*",
    name: "notfound",
    component: notfound,
    meta: {
      title: "خطأ فى العنوان",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next();
    } else {
      next("/Products");
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  }
});
